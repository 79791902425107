.oskw-image-card {
  --color-gray-40: hsl(0, 0%, 40%);
  --color-gray-60: hsl(0, 0%, 60%);
  --color-gray-85: hsl(0, 0%, 85%);
  --color-gray-98: hsl(0, 0%, 98%);

  --color-digital-blue: hsl(193, 83%, 52%);

  --color-highlight: var(--color-digital-blue);

  --space-xxs:    2px;
  --space-xs:     4px;
  --space-s:      8px;
  --space-m:      16px;
  --space-l:      32px;
  --space-xl:     64px;
  --space-xxl:    128px;

  --space-inset-xxs:   2px 2px 2px 2px;
  --space-inset-xs:    4px 4px 4px 4px;
  --space-inset-s:     8px 8px 8px 8px;
  --space-inset-m:     16px 16px 16px 16px;
  --space-inset-l:     32px 32px 32px 32px;
  --space-inset-xl:    64px 64px 64px 64px;
  --space-inset-xxl:   128px 128px 128px 128px;

  --space-inset-squish-s:     4px 8px 4px 8px;
  --space-inset-squish-m:     8px 16px 8px 16px;
  --space-inset-squish-l:     16px 32px 16px 32px;

  --space-inset-stretch-s:     12px 8px 12px 8px;
  --space-inset-stretch-m:     24px 16px 24px 16px;

  --space-inline-xxs:   0px 2px 0px 0px;
  --space-inline-xs:    0px 4px 0px 0px;
  --space-inline-s:     0px 8px 0px 0px;
  --space-inline-m:     0px 16px 0px 0px;
  --space-inline-l:     0px 32px 0px 0px;
  --space-inline-xl:    0px 64px 0px 0px;
  --space-inline-xxl:   0px 128px 0px 0px;

  --space-inline-reverse-xxs:   0px 0px 0px 2px;
  --space-inline-reverse-xs:    0px 0px 0px 4px;
  --space-inline-reverse-s:     0px 0px 0px 8px;
  --space-inline-reverse-m:     0px 0px 0px 16px;
  --space-inline-reverse-l:     0px 0px 0px 32px;
  --space-inline-reverse-xl:    0px 0px 0px 64px;
  --space-inline-reverse-xxl:   0px 0px 0px 128px;

  --space-stack-xxs:   0px 0px 2px 0px;
  --space-stack-xs:    0px 0px 4px 0px;
  --space-stack-s:     0px 0px 8px 0px;
  --space-stack-m:     0px 0px 16px 0px;
  --space-stack-l:     0px 0px 32px 0px;
  --space-stack-xl:    0px 0px 64px 0px;
  --space-stack-xxl:   0px 0px 128px 0px;

  display: block;
  padding: var(--space-inset-s);
  transition: transform .3s ease, opacity .3s ease;
}

.oskw-image-card * {
  box-sizing: border-box;
}

.oskw-inner-content {
  display: block;
  text-decoration: none;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
  transition: transform .3s ease;
  border-radius: 10px;
  overflow: hidden;
}

.oskw-image-card .oskw-inner-content:hover {
  transform: scale(1.01);
}

.oskw-image-card .oskw-inner-content img {
  border: none;
  display: block;
}

.oskw-image-card .oskw-info {
  padding: var(--space-inset-stretch-s);
  line-height: 1.5;
  font-size: .9rem;
  font-family: sans-serif;
}

.oskw-image-card .oskw-info .oskw-title {
  font-size: 1rem;
  color: var(--color-gray-40);
}

.oskw-image-card .oskw-info .oskw-date { color: var(--color-gray-60); }

.oskw-image-card .oskw-img-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
}

 .oskw-image-card .oskw-img-wrapper img {
  width: 100%;
  height: auto;
}
