.ossy-stack__item {
  flex-shrink: 0;
}

.ossy-stack__item[data-fill="true"] {
  flex: 1 1 auto;
}

.ossy-stack[data-horizontal="false"][data-bordered="true"] > .ossy-stack__item {
  border-top: 1px solid var(--separator-primary);
}

.ossy-stack[data-horizontal="false"][data-bordered="true"] > .ossy-stack__item:first-child {
  border-top: none;
}

.ossy-stack[data-horizontal="true"][data-bordered="true"] > .ossy-stack__item {
  border-left: 1px solid var(--separator-primary);
}

.ossy-stack[data-horizontal="true"][data-bordered="true"] > .ossy-stack__item:first-child {
  border-left: none;
}
